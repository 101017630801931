import { ref } from 'vue';

import { defineStore } from 'pinia';

import api from '@eencloud/eewc-components/src/service/api';
import {
  ResponderBulkCreate,
  ResponderBulkDelete,
  ResponderCameraBulkCreate,
  ResponderCameraBulkDelete,
  ResponderGet,
} from '@eencloud/eewc-components/src/service/api-types/firstResponders';

import { useMessagingStore } from '../stores';
import { t } from '@/plugins/i18n';

export const useFirstRespondersStore = defineStore('firsResponders', () => {
  const messagingStore = useMessagingStore();

  const firstResponders = ref<ResponderGet[]>();
  const loadingResponders = ref(false);

  const actions = {
    // Responder Methods
    async getPagedResponders(accountId: string) {
      let responders: ResponderGet[] = [];
      let pageToken: string | undefined;

      try {
        loadingResponders.value = true;
        const data = await api.getResponders(accountId, { pageToken: pageToken as string, pageSize: 500 });
        if (data) {
          pageToken = data.nextPageToken ?? '';
          responders = [...responders, ...(data.results as ResponderGet[])];
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingResponders.value = false;
      }

      firstResponders.value = responders;
    },

    async createResponders(accountId: string, payload: ResponderBulkCreate) {
      try {
        loadingResponders.value = true;
        const res = await api.createResponders(accountId, payload);
        return res;
      } catch (error) {
        console.error(error);
      } finally {
        loadingResponders.value = false;
      }
    },

    async deleteResponders(accountId: string, payload: ResponderBulkDelete) {
      try {
        loadingResponders.value = true;
        const res = await api.deleteResponders(accountId, payload);

        if (res && res.every((item) => item.status === 204)) {
          messagingStore.showSuccessMessage(t('Responder was successfully removed from First responders list'));
        }

        return res;
      } catch (error) {
        console.error(error);
      } finally {
        loadingResponders.value = false;
      }
    },

    // Responder Cameras Methods
    async getResponderCameras(accountId: string) {
      const res = await api.getResponderCameras(accountId);
      return res;
    },

    async createResponderCameras(accountId: string, payload: ResponderCameraBulkCreate) {
      const res = await api.createResponderCameras(accountId, payload);
      return res;
    },

    async deleteResponderCameras(accountId: string, payload: ResponderCameraBulkDelete) {
      const res = await api.deleteResponderCameras(accountId, payload);
      return res;
    },
  };

  return {
    firstResponders,
    loadingResponders,
    ...actions,
  };
});
