import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePersistedStateStore = defineStore(
  'persistedState',
  function () {
    const miniMenuBar = ref<boolean>(false);

    const actions = {
      setMiniMenuBar(state: boolean) {
        miniMenuBar.value = state;
      },
    };

    return {
      miniMenuBar,
      ...actions,
    };
  },
  {
    persist: true,
  }
);
