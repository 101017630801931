<template>
  <eewc-dropdown-menu
    :tooltip="tooltipText"
    :menu-items="dropdownMenuItems"
    @select="dropdownMenuAction"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ActionType } from '../../types';
import { t } from '@/plugins/i18n.ts';

const props = defineProps<{
  hasData: boolean;
  chart: any;
  tooltipText: string;
  disabled?: boolean;
}>();

const dropdownMenuItems = computed(() => [
  { name: t('Print chart'), value: 'print-chart', disabled: !props.hasData },
  { name: t('Download PNG'), value: 'download-png', disabled: !props.hasData },
  { name: t('Download JPEG'), value: 'download-jpeg', disabled: !props.hasData },
  { name: t('Download PDF'), value: 'download-pdf', disabled: !props.hasData },
  { name: t('Download SVG'), value: 'download-svg', disabled: !props.hasData },
  { name: t('Download CSV'), value: 'download-csv', disabled: !props.hasData },
  { name: t('Download XLS'), value: 'download-xls', disabled: !props.hasData },
]);

function dropdownMenuAction(action: ActionType) {
  const chart = props.chart;

  switch (action) {
    case 'print-chart':
      chart.print();
      break;
    case 'download-png':
      chart.exportChart({
        type: 'image/png',
        filename: 'analytics-chart',
      });
      break;
    case 'download-jpeg':
      chart.exportChart({
        type: 'image/jpeg',
        filename: 'analytics-chart',
      });
      break;
    case 'download-pdf':
      chart.exportChart({
        type: 'application/pdf',
        filename: 'analytics-chart',
      });
      break;
    case 'download-svg':
      chart.exportChart({
        type: 'image/svg+xml',
        filename: 'analytics-chart',
      });
      break;
    case 'download-csv':
      chart.downloadCSV();
      break;
    case 'download-xls':
      chart.downloadXLS();
      break;
  }
}
</script>
