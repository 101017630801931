import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiBridgeCreate,
  ApiBridgeUpdate,
  ApiBridgeWithIncludes,
  SwapBridgeParams,
  GetBridgeId,
  GetBridgeParams,
  PatchBridgeSettings,
} from '@eencloud/eewc-components/src/service/api-types';
import { t } from '@/plugins/i18n.ts';
import { defineStore } from 'pinia';
import { useMessagingStore } from '@/stores';

interface State {
  bridges: ApiBridgeWithIncludes[];
  allBridgesByCustomParam: ApiBridgeWithIncludes[];
  loadingBridges: boolean | undefined;
  messagingStore: any;
}

export const useBridgesStore = defineStore('bridges', {
  state: (): State => ({
    bridges: [],
    allBridgesByCustomParam: [],
    messagingStore: useMessagingStore(),
    loadingBridges: undefined,
  }),
  actions: {
    /**
     * the function will fetch all brdiges based on GetBridgeParams params, currently it will be used to fetch bridges for a location
     * but in future it can be extended to other things
     * Note - the include param does not have all properties, as bridge object for a location does not require all of the properties
     * @param params GetBridgeParams
     */
    async getAllBridgesByCustomParam(params: GetBridgeParams) {
      let bridgesList: ApiBridgeWithIncludes[] = [];
      this.allBridgesByCustomParam = [];
      try {
        let pageToken: string | undefined;
        do {
          const data = await api.getBridges({
            pageToken,
            pageSize: 500,
            ...params,
            include: params.include ? params.include.toString() : undefined,
          });
          if (data) {
            pageToken = data.nextPageToken;
            bridgesList = [...bridgesList, ...(data.results as ApiBridgeWithIncludes[])];
          } else break;
        } while (pageToken !== '');
      } catch (error) {
        console.error(error);
      }
      this.allBridgesByCustomParam = bridgesList;
      return bridgesList;
    },

    async getBridgeSettings(bridgeId: GetBridgeId) {
      try {
        return await api.getBridgeSettings(bridgeId);
      } catch (error) {
        console.error(error);
      }
    },

    async updateBridgeSettings(bridgeId: GetBridgeId, payload: PatchBridgeSettings) {
      return await api.patchBridgeSettings(bridgeId, payload);
    },

    async getBridge(bridgeId: GetBridgeId, params?: GetBridgeParams) {
      try {
        return await api.getBridge(bridgeId, params);
      } catch (error) {
        console.error(error);
      }
    },

    async getPagedBridges(params: GetBridgeParams) {
      let bridges: ApiBridgeWithIncludes[] = [];
      let nextPageToken = '';
      let prevPageToken = '';
      let totalSize = 0;
      try {
        const data = await api.getBridges(params);
        if (data) {
          nextPageToken = data?.nextPageToken ?? '';
          prevPageToken = data?.prevPageToken ?? '';
          totalSize = data.totalSize ?? 0;
          bridges = data.results as ApiBridgeWithIncludes[];
        }
      } catch (error) {
        console.error(error);
      }
      return { bridges, totalSize, nextPageToken, prevPageToken };
    },

    async deleteBridge(bridgeId: GetBridgeId) {
      try {
        await api.deleteBridge(bridgeId);
        this.allBridgesByCustomParam = this.allBridgesByCustomParam.filter((bridge) => bridge.id !== bridgeId);
      } catch (error) {
        console.error(error);
      }
    },

    async swapBridge(params: SwapBridgeParams, newBridgeName: string) {
      const request = await api.swapBridge(params);
      if (request) {
        // this is done to refresh the Devices table on dashboard post successful bridge swap operation
        await this.getAllBridgesByCustomParam({ include: 'effectivePermissions,status,resourceStatusCounts' });
        this.messagingStore?.addNotification({
          iconColor: 'positive',
          icon: '$icon_check_zero',
          title: t(`Bridge added`),
          body: t('{deviceName} is now in the My devices list', {
            deviceName: newBridgeName,
          }),
        });
      }
    },

    async addBridge(payload: ApiBridgeCreate) {
      const data = await api.addBridge(payload);

      if (data) {
        this.messagingStore?.addNotification({
          iconColor: 'positive',
          icon: '$icon_check_zero',
          title: t(`Bridge added`),
          body: `${data.name} ${t('has been successfully added')}`,
        });
      }
    },
    async updateBridge(payload: ApiBridgeUpdate & { bridgeId: GetBridgeId }) {
      const { bridgeId, ...newPayload } = payload;
      return await api.updateBridge({ bridge: newPayload, bridgeId });
    },
  },
});
