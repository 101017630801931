import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiAddSpeaker,
  ApiSpeakerIncludes,
  ApiSpeakerUpdate,
  GetSpeakersParams,
  LocationId,
  PaginatedSpeakerResponse,
  SpeakerWithIncludes,
} from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { t } from '@/plugins/i18n.ts';
import { useAvailableDevicesStore, useCamerasStore, useMessagingStore } from '.';
import router from '@/service/router';

export const useSpeakersStore = defineStore('speakers', function () {
  const camerasStore = useCamerasStore();
  const messagingStore = useMessagingStore();
  const speakers = ref<SpeakerWithIncludes[]>([]);
  const loadingSpeakers = ref<boolean | undefined>(undefined);

  const getters = {
    speaker: (speakerId: string) => speakers.value.find((speaker) => speaker.id === speakerId),
  };

  async function getAllSpeakers(locationId?: LocationId) {
    try {
      loadingSpeakers.value = true;
      let totalSize: number | undefined;
      let pageToken: string | undefined;

      while (totalSize === undefined || totalSize > speakers.value.length) {
        const data = (await api.getSpeakers({
          pageToken,
          locationId__in: locationId,
        })) as PaginatedSpeakerResponse;
        if (data?.results) {
          totalSize = data.totalSize;
          pageToken = data.nextPageToken;
          speakers.value = data.results;
          loadingSpeakers.value = false;
        } else break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingSpeakers.value = false;
    }
  }

  async function getAllSpeakersByCustomParam(params: GetSpeakersParams) {
    let speakerList: ApiSpeakerIncludes[] = [];
    try {
      let pageToken: string | undefined;
      do {
        const data = (await api.getSpeakers({
          pageToken: pageToken as string,
          pageSize: 500,
          include: params.include ? params.include.toString() : undefined,
          ...params,
        })) as PaginatedSpeakerResponse;
        if (data) {
          pageToken = data.nextPageToken;
          speakerList = [...speakerList, ...(data.results as ApiSpeakerIncludes[])];
        } else break;
      } while (pageToken !== '');
    } catch (error) {
      console.error(error);
    }
    return speakerList;
  }

  async function getPagedSpeakers(params: GetSpeakersParams) {
    let speakers: SpeakerWithIncludes[] = [];
    let nextPageToken = '';
    let prevPageToken = '';
    let totalSize = 0;
    try {
      const res = await api.getSpeakers(params);
      if (res) {
        const data = res as PaginatedSpeakerResponse;
        nextPageToken = data?.nextPageToken ?? '';
        prevPageToken = data?.prevPageToken ?? '';
        totalSize = data.totalSize ?? 0;
        speakers = data.results as SpeakerWithIncludes[];
      }
    } catch (error) {
      console.error(error);
    }
    return { speakers, totalSize, nextPageToken, prevPageToken };
  }

  async function addSpeaker(payload: ApiAddSpeaker) {
    const availableDevicesStore = useAvailableDevicesStore();
    try {
      const data = await api.addSpeaker(payload);
      if (data) {
        if (data.id) {
          const speakerData = (await api.getSpeakers({
            speakerId: data.id,
          })) as SpeakerWithIncludes; // get the added speaker details to refresh the mydevice table
          if (speakerData) {
            speakers.value = speakers.value.concat(speakerData);
          }

          availableDevicesStore.availableDevices = availableDevicesStore.availableDevices.filter(
            (ad) => ad.guid !== payload.guid
          );

          messagingStore.showSuccessMessage(
            t('The {speakerName} speaker was successfully added to Dashboard.', { speakerName: payload.name })
          );
          return data.id;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return undefined;
  }

  async function getSpeaker(speakerId: string) {
    return (await api.getSpeakers({ speakerId })) as SpeakerWithIncludes;
  }

  async function deleteSpeaker(speakerId: string) {
    try {
      await api.deleteSpeaker(speakerId);
      speakers.value = speakers.value.filter((speaker) => speaker.id !== speakerId);

      camerasStore.allCamerasByCustomParam.forEach((cam) => {
        cam.speakerId = cam.speakerId && cam.speakerId === speakerId ? undefined : cam.speakerId;
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function editSpeaker(editSpeaker: ApiSpeakerUpdate, speakerId: string) {
    try {
      const status = await api.editSpeaker(editSpeaker, speakerId);
      if (status) {
        speakers.value = speakers.value.map((speaker) =>
          speaker.id === speakerId
            ? {
                ...speaker,
                name: editSpeaker.name as string,
                notes: editSpeaker.notes as string,
              }
            : speaker
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function bulkUpdateCamerasSpeaker(cameras: string[], speakerId: string | null) {
    const bulkPayloadSpeaker = {
      ids: cameras,
      updateFields: {
        speakerId,
      },
    };

    return await camerasStore.bulkUpdateCamera(bulkPayloadSpeaker);
  }

  async function createTunnel(deviceId: string) {
    return await api.createDeviceTunnel(deviceId, 'speakers');
  }

  async function deleteTunnel(deviceId: string) {
    return await api.deleteDeviceTunnel(deviceId, 'speakers');
  }

  return {
    ...getters,
    speakers,
    loadingSpeakers,
    getAllSpeakers,
    addSpeaker,
    deleteSpeaker,
    editSpeaker,
    bulkUpdateCamerasSpeaker,
    getSpeaker,
    createTunnel,
    deleteTunnel,
    getAllSpeakersByCustomParam,
    getPagedSpeakers,
  };
});
