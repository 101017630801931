<template>
  <div>
    <div class="control-options">
      <grouped-options
        :selected-option="selectedGrouping"
        :options="GROUPING_OPTIONS"
        @update:handleSelect="handleDataGrouping"
      />
      <div class="right-side-control-group">
        <metrics-event-type-dropdown
          :supported-events-dropdown-list="eventsDropdown"
          :selected-event-type="selectedEventType"
          :is-loading="isAnalyticsEventChartLoading"
          @input="handleEventTypeChange"
        />
        <refresh-button
          :tool-tip-text="t('Refresh')"
          :loading="isAnalyticsEventChartLoading"
          @click="refetchEventMetrics"
        />
        <eewc-skeleton
          v-if="isAnalyticsEventChartLoading"
          type="big-icon"
        />
        <metrics-download-options-dropdown
          v-else
          :chart="highChartsRef.chart"
          :has-data="hasData"
          :tooltip-text="t('More')"
        />
      </div>
    </div>
    <highcharts
      :key="chartColors.length"
      ref="highChartsRef"
      constructor-type="stockChart"
      :options="chartData"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';

import { t, tc } from '@/plugins/i18n.ts';
import router from '@/service/router';
import { useAppStateStore, useCamerasStore, useUsersStore } from '@/stores';
import { useGetDeviceSupportedEvents, useGetCameraSettings, useGetEventMetrics } from '@/queries';

import {
  getChartOptionsBaseConfig,
  getDataGroupingConfig,
  getFilteredNonZeroDataPoints,
  getMinAndMaxDate,
  getSeriesConfig,
} from './chartUtils';
import GroupedOptions from './components/GroupedOptions.vue';
import RefreshButton from './components/RefreshButton.vue';
import MetricsEventTypeDropdown from './components/MetricsEventTypeDropdown.vue';
import MetricsDownloadOptionsDropdown from './components/MetricsDownloadOptionsDropdown.vue';

import {
  COLOR_FOR_ANALYTIC_EVENT,
  MAX_ALLOWED_RETENTION_DAYS,
  ONE_HOUR_INTERVAL,
  REQUIRED_EVENT_TYPE_LIST,
  GROUPING_OPTIONS,
  LINE_CROSSING_IN,
  LINE_CROSSING_OUT,
  LINE_CROSSING_EVENT,
  LINE_CROSS_EVENT_TYPE,
} from '../constants';
import { useGetSystemEventTypes } from '@/queries/events/useGetSystemEvents';
import { SelectBoxItem } from '@eencloud/eewc-components/src/components/dropdowns/types';
import { EventType } from '@eencloud/eewc-components/src/service/api-types';

const camerasStore = useCamerasStore();
const appStateStore = useAppStateStore();
const usersStore = useUsersStore();

const highChartsRef = ref();
const label = ref<string>('events');
const offsetDate = ref<string>();
const selectedEventType = ref(REQUIRED_EVENT_TYPE_LIST[0]);
const selectedGrouping = ref(ONE_HOUR_INTERVAL);
const title = ref<string>('');
const chartColors = ref<string[]>([]);

const chartData = computed(() => {
  const isAnalyticEvent = !!chartColors.value.length;

  if (isAnalyticEvent) {
    return {
      ...chartOptions.value,
      colors: chartColors.value,
    };
  }

  return chartOptions.value;
});

const eventsDropdown = computed(() =>
  systemSupportedEvents.value?.results?.reduce<SelectBoxItem[]>((acc, event) => {
    if (isSupportedEvent(event)) {
      event.type === LINE_CROSS_EVENT_TYPE
        ? acc.push(...LINE_CROSSING_EVENT)
        : acc.push({ text: event.name, value: event.type });
    }
    return acc;
  }, [])
);

const hasData = computed(() => eventMetricsFetching.value || !!getDataPoints()?.length);
const deviceId = computed<string>(() => {
  return router.currentRoute.params.id;
});
const chartOptions = computed(() => Object.assign({}, getChartOptionsBaseConfig(label.value, title.value)));

const eventMetricsParams = computed(() => ({
  actor: `camera:${deviceId.value}`,
  eventType: selectedEventType.value.includes('line-cross') ? 'een.objectLineCrossEvent.v1' : selectedEventType.value,
  timestamp__lte: new Date().toEENApiDateTime(),
  timestamp__gte: getChartStartDate(cameraSettings.value?.data.retention?.cloudDays),
}));

const systemSupportedEventMetricsParams = {
  language: usersStore.currentUser?.language || 'en',
};

const cameraSettingsParams = computed(() => ({
  include: 'proposedValues',
}));

const eventMetricsEnabled = computed(() => !!cameraSettings.value?.data.retention?.cloudDays);

const isAnalyticsEventChartLoading = computed(() => supportedEventsLoading.value || eventMetricsFetching.value);

onMounted(async () => {
  setTopBarTitle();
});

function isSupportedEvent(event: EventType) {
  return supportedEvents.value?.type?.includes(event.type) && REQUIRED_EVENT_TYPE_LIST.includes(event.type);
}

const { data: supportedEvents, isLoading: supportedEventsLoading } = useGetDeviceSupportedEvents(deviceId);
const { data: cameraSettings } = useGetCameraSettings(deviceId, cameraSettingsParams);
const {
  data: eventMetrics,
  refetch: refetchEventMetrics,
  isFetching: eventMetricsFetching,
} = useGetEventMetrics(eventMetricsParams, eventMetricsEnabled);
const { data: systemSupportedEvents } = useGetSystemEventTypes(systemSupportedEventMetricsParams);

async function setTopBarTitle() {
  const camera = await camerasStore.getCamera(deviceId.value);

  appStateStore.changeTopBarBreadcrumbs(
    Object.assign({}, appStateStore.topBarBreadcrumbs, {
      title: camera?.name ? `${t('Analytics graphs')} • ${camera.name}` : t('Analytics graphs'),
    })
  );
}

function getChartStartDate(retentionDays: number | undefined): string | undefined {
  if (typeof retentionDays === 'number' && retentionDays > 0) {
    const daysBefore = retentionDays > MAX_ALLOWED_RETENTION_DAYS ? MAX_ALLOWED_RETENTION_DAYS : retentionDays;
    const date = new Date();
    date.setDate(date.getDate() - daysBefore);
    return date.toEENApiDateTime();
  }
}

function renderMetrics() {
  label.value = '';
  const dataPoints = getDataPoints();
  const series = [getSeriesConfig(tc('events', dataPoints?.length), dataPoints, selectedGrouping.value)];

  const { min, max } = getMinAndMaxDate(dataPoints, offsetDate.value);

  const isAnalyticEvent = selectedEventType.value !== REQUIRED_EVENT_TYPE_LIST[0];

  chartColors.value = isAnalyticEvent ? COLOR_FOR_ANALYTIC_EVENT : [];
  title.value = !hasData.value ? t('No data available') : '';

  chartOptions.value.xAxis = {
    ...chartOptions.value.xAxis,
    min,
    max,
  };

  chartOptions.value.series = series;
  highChartsRef.value.chart.update(chartOptions.value, true, true);
}

function handleDataGrouping(intervalInHours: number) {
  selectedGrouping.value = intervalInHours;
  const chart = highChartsRef.value.chart;
  const dataGroupingConfig = getDataGroupingConfig(intervalInHours);

  if (chart?.series.length) {
    chart.series[0].update({ dataGrouping: dataGroupingConfig }, false);
    chart.redraw();
  }
}

function getDataPoints() {
  if (!eventMetrics.value?.length) return [];

  const isLineCrossing = [LINE_CROSSING_IN, LINE_CROSSING_OUT].includes(selectedEventType.value);

  // The line crossing event will return an array of two datapoints. All other events return single item in an array. The first one in line crossing event
  // represents the line crossing in event and second item represents line crossing out event. Below line of code selects the correct item from incoming
  // array based on events
  const eventIndex =
    isLineCrossing && eventMetrics.value.length ? (selectedEventType.value === LINE_CROSSING_IN ? 0 : 1) : 0;

  return getFilteredNonZeroDataPoints(eventMetrics.value[eventIndex]?.dataPoints);
}

function handleEventTypeChange(option: { value: string; text: string }) {
  selectedEventType.value = option.value;
  refetchEventMetrics();
}

watch(
  eventMetricsFetching,
  (newVal, oldVal) => {
    const chart = highChartsRef.value?.chart;
    if (newVal && newVal !== oldVal) {
      chart?.showLoading(t('Loading data...'));
      title.value = '';
    } else {
      chart?.hideLoading();
    }
  },
  { immediate: true }
);

watch(eventMetrics, () => {
  renderMetrics();
});
</script>

<style lang="scss" scoped>
@import '@/styles/public/main.scss';

.control-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 64px;
}

.selected-button {
  color: $accentFixed;
  background-color: $accentLight;
  border-radius: 4px;
}

.right-side-control-group {
  display: flex;
  gap: 12px;
  align-items: center;
}

.data-grouping-buttons {
  display: flex;
  background-color: $backgrounds;
  padding: 12px 0px;
  border-radius: 4px;
  border: 1px solid $elements;
}

.data-grouping-buttons button {
  flex: 1;
  background-color: transparent;
  margin: 0 8px;
  padding: 0 4px;
  border: none;
  font-size: $font-size-root;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
}

.data-grouping-buttons button.active {
  background-color: $accentLight;
  color: $accent;
}

.data-grouping-buttons button:hover {
  background-color: $accentLight;
}

.data-grouping-buttons .button-parent {
  display: flex;
  width: 62px;
}

.data-grouping-buttons .button-parent:not(:last-child) {
  border-right: 1px solid $elements;
}
</style>
