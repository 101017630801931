<template>
  <eewc-select-box
    class="event-metrics-dropdown"
    :items="supportedEventsDropdownList"
    :value="selectedEventType"
    :disabled="isDropdownDisabled"
    :loading="isLoading"
    @input="handleEventTypeChange"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { SelectBoxItem } from '@eencloud/eewc-components/src/components/dropdowns/types';
import { EventOption } from '../../types';

const props = defineProps<{
  supportedEventsDropdownList: SelectBoxItem[] | undefined;
  selectedEventType: string;
  isLoading: boolean;
}>();

const emit = defineEmits<{
  (e: 'input', value: EventOption): void;
}>();

const isDropdownDisabled = computed(() => props.supportedEventsDropdownList?.length === 1);

function handleEventTypeChange(option: EventOption) {
  emit('input', option);
}
</script>

<style lang="scss" scoped>
@import '@/styles/public/main.scss';

.event-metrics-dropdown {
  width: 200px;
}
</style>
