import { computed, ref } from 'vue';
import router from '@/service/router';
import { t } from '@/plugins/i18n.ts';

import { useAppStateStore } from '@/stores';

function useSearchbar() {
  const appState = useAppStateStore();

  let bounceTimer = null;
  const searchQuery = ref('');

  function applySearch(searchValue) {
    clearTimeout(bounceTimer);
    bounceTimer = setTimeout(() => {
      appState.updateSearchQuery(searchValue);
    }, 500);
  }

  const isUsersManagePage = () =>
    appState.currentRoute?.name === 'Users' && appState.currentRoute.params.id === 'manage';

  const hasFilterOption = () =>
    router.options.routes.some((route) => route.name === appState.currentRoute?.name && route.meta.filterOption);

  const isSearchBarVisible = computed(() => {
    if (appState.currentRoute?.name === 'Reports') {
      return false;
    }

    return isUsersManagePage() || hasFilterOption();
  });

  const filterName = computed(() => {
    switch (appState.currentRoute?.name) {
      case 'All cameras layout':
      case 'Layouts':
      case 'Tags':
      case 'Map':
      case 'VideoSearch':
        return t('cameras');
      case 'Dashboard':
        return t('cameras and bridges');
      case 'Archive':
      case 'Download':
        return t('files or folder');
      case 'Measurements':
        return t('sensors');
      default:
        return appState.currentRoute?.name.toLowerCase();
    }
  });

  const filterPlaceHolder = computed(() => `${t('Filter for')} ${filterName.value}`);
  return { searchQuery, applySearch, isSearchBarVisible, filterPlaceHolder };
}

export default useSearchbar;
