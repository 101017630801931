import { Ref, computed, reactive, ref, watchEffect } from 'vue';

import { IntersectionStateChanger } from '@eencloud/eewc-components/src/utils/types';
import _ from 'lodash';

import { useAppStateStore, useAuthStateStore, useCamerasStore } from '@/stores';
import { t } from '@/plugins/i18n';

function useResponderNavigation(responderActiveRoute: Ref<string>, activeRouteParam: Ref<string>) {
  const appState = useAppStateStore();
  const camerasStore = useCamerasStore();
  const authStateStore = useAuthStateStore();

  const pagedCamerasInfiniteState = ref<IntersectionStateChanger>();
  const pagedCamerasNextPageToken = ref<string | undefined>(undefined);

  const showHiddenFeaturesRef = computed(() => appState.showHiddenFeatures);

  const responderSubMenuItems = computed(() => {
    if (camerasStore.firstResponderSubAccounts.length) {
      const distinctAccounts = _.uniqBy(camerasStore.firstResponderSubAccounts, 'id');
      const accountsOfSharedCameras = distinctAccounts.map((account) => {
        return {
          ...account,
          active: activeRouteParam.value === account?.id,
        };
      });

      return accountsOfSharedCameras;
    } else {
      return [];
    }
  });

  // During the initial app load, the API call returned a 404 error because the bearer token was undefined. Now, the call will only be made after the user successfully logs in
  watchEffect(() => {
    if (authStateStore.loggedIn) {
      getFirstResponderSubAccounts();
    }
  });

  const responderNavigationData = reactive({
    items: computed(() => {
      return (
        camerasStore.firstResponderSubAccounts.length && {
          icon: '$icon_first_responder',
          name: t('First responder'),
          active: responderActiveRoute.value === 'Responder',
          subItems: responderSubMenuItems.value,
          route: 'Responder',
          infiniteHandler: pagedCamerasNextPageToken.value && accountsInfiniteHandler,
        }
      );
    }),
  });

  async function getFirstResponderSubAccounts() {
    const { cameras, nextPageToken } = await camerasStore.getPagedCameras({
      include: 'shareDetails',
      'shareDetails.firstResponder': true,
    });

    const accountsOfSharedCameras = cameras.map((camera) => {
      return {
        id: camera.shareDetails?.accountId,
        name: camera.shareDetails?.accountName,
      };
    });
    camerasStore.firstResponderSubAccounts = [...camerasStore.firstResponderSubAccounts, ...accountsOfSharedCameras];
    pagedCamerasNextPageToken.value = nextPageToken;
  }

  async function accountsInfiniteHandler(state: IntersectionStateChanger) {
    pagedCamerasInfiniteState.value = state;
    if (!camerasStore.pagedCamerasLoading) {
      if (pagedCamerasNextPageToken.value === '') {
        return pagedCamerasInfiniteState.value?.completed();
      }
      camerasStore.pagedCamerasLoading = true;
      if (pagedCamerasNextPageToken.value === undefined) {
        camerasStore.pagedCameras = [];
      }

      getFirstResponderSubAccounts();

      camerasStore.pagedCamerasLoading = false;
      pagedCamerasInfiniteState.value?.loaded();
      if (pagedCamerasNextPageToken.value === '') {
        pagedCamerasInfiniteState.value?.completed();
      }
    }
  }

  return { responderNavigationData };
}

export default useResponderNavigation;
