import { t } from '@/plugins/i18n';
import { Item } from '@eencloud/eewc-components/src/components/dropdowns/types';
import {
  ConfigurationPatch,
  CountingAndCrossingConfiguration,
  MotionConfiguration,
  MotionObjectSize,
} from '@eencloud/eewc-components/src/service/api-types';

export const SKELETON_ROW_COUNT = 5;
export const ANALYTIC_TYPE = {
  MOTION_DETECTION: 'een.motionDetectionEvent.v1',
  LINE_CROSSING: 'een.objectLineCrossEvent.v1',
  OBJECT_COUNTING: 'een.countChangeEvent.v1',
  OBJECT_DETECTION: 'een.bridgeCommonSettings.v1',
  OBJECT_INTRUSION: 'een.objectIntrusionEvent.v1',
  OBJECT_LOITERING: 'een.loiterDetectionEvent.v1',
  TAMPER_DETECTION: 'een.tamperDetectionEvent.v1',
};

export const DISABLED_MENU_ITEM: Item = { text: t('Disabled'), value: 'disabled' };
export const PERFORMED_ON = {
  BRIDGE: 'bridge',
  DISABLED: 'disabled',
  CAMERA: 'camera',
};

export const CONFIGURATION_TYPE = {
  TUNNEL: 'tunnel',
  NOTE: 'note',
  SETTINGS: 'settings',
};

export type Size = {
  width: number;
  height: number;
};

export type UPDATED_CONFIGURATION = {
  id: string;
  performedOn: string;
  configurationType?: string;
  configuration?: ConfigurationPatch[]; // each analytic type can be configured using different performedOn types - bridge, camera, cloud etc. For each one of then we need to send a separate configuration
  isDirty?: boolean; // flag to check if the configuration is dirty or not
  selectedConfigurationIndex?: number; // index of the active/selected configuration, it can be undefined when the state is disabled
};

export const DEFAULT_LINE_CROSS_CONFIGURATION: CountingAndCrossingConfiguration = {
  lines: [
    {
      name: 'New line',
      direction: 'up',
      coordinates: [
        [0.049, 0.119],
        [0.919, 0.928],
      ],
    },
  ],
};

export const DEFAULT_MOTION_CONFIGURATION: MotionConfiguration = {
  sensitivity: 0.8,
  objectSize: MotionObjectSize.Small,
  regions: [],
};

export const DEFAULT_OBJECT_INTRUSION_CONFIGURATION = {
  regions: [],
};

export const TAMPER_DETECTION_DEFAULT_CONFIGURATION_FOR_BRIDGE = {
  sensitivity: 0.8,
};

export const DEFAULT_CONFIGURATION = {
  [ANALYTIC_TYPE.TAMPER_DETECTION]: TAMPER_DETECTION_DEFAULT_CONFIGURATION_FOR_BRIDGE,
  [ANALYTIC_TYPE.LINE_CROSSING]: DEFAULT_LINE_CROSS_CONFIGURATION,
  [ANALYTIC_TYPE.OBJECT_COUNTING]: DEFAULT_LINE_CROSS_CONFIGURATION,
  [ANALYTIC_TYPE.MOTION_DETECTION]: DEFAULT_MOTION_CONFIGURATION,
  [ANALYTIC_TYPE.OBJECT_INTRUSION]: DEFAULT_OBJECT_INTRUSION_CONFIGURATION,
};

// Default canvas size for the analytic configuration
export const DEFAULT_CANVAS_SIZE: Size = { width: 1270, height: 760 };
