import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiRuleMain,
  GetRulesParams,
  CreateAuditLogAlertConditionRule,
  EditAuditLogAlertConditionRule,
  CreateEventAlertConditionRuleRequestPayload,
  EditEventAlertConditionRuleRequestPayload,
  EventAlertConditionRulesListResponse,
  EventAlertConditionRuleResponse,
  EventAlertFieldValuesResponse,
} from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import usePagination from '@/service/usePagination';

export const useRulesStore = defineStore('rules', function () {
  const rules = ref<ApiRuleMain[]>([]);
  const loadingRules = ref(false);
  const rulesTotalLength = ref<number>(0);
  const currentRuleName = ref<string>();
  const nextPageToken = ref<string | undefined>();
  const prevPageToken = ref<string | undefined>();
  const filterRules = ref({});
  const { getPageToken, updatePaginationData, paginationParams } = usePagination();
  const createRuleBase = ref<EditAuditLogAlertConditionRule>();
  const sourceType = ref<string>();
  const backToPreviousPageInfo = ref();

  async function getRules(payload?: GetRulesParams) {
    loadingRules.value = true;

    if (!payload) {
      payload = {
        pageSize: paginationParams.pageSize,
      };
    }

    if (filterRules.value) {
      payload = { ...payload, ...filterRules.value };
    }

    try {
      const data = await api.getRules(payload);
      if (!data?.results) {
        return;
      }
      rules.value = data?.results;
      rulesTotalLength.value = data.totalSize || 0;
      nextPageToken.value = data.nextPageToken;
      prevPageToken.value = data.prevPageToken;
    } catch {
      // err
      rules.value = [];
    }
    loadingRules.value = false;
  }

  async function getRule(ruleId: string) {
    try {
      const data = await api.getAuditLogAlertConditionRules(ruleId);
      return data as CreateAuditLogAlertConditionRule;
    } catch (error) {
      console.log(error);
    }
    return (await api.getAuditLogAlertConditionRules(ruleId)) as CreateAuditLogAlertConditionRule;
  }

  async function addRule(payload: CreateAuditLogAlertConditionRule) {
    return await api.addAuditLogAlertConditionRule(payload);
  }

  async function updateRule(ruleId: string, payload: EditAuditLogAlertConditionRule) {
    const params = { ruleId, payload };
    return await api.updateAuditLogAlertConditionRule(params);
  }

  function updateRuleInStore(ruleId: string, payload: EditAuditLogAlertConditionRule) {
    rules.value = rules.value.map((rule) => (rule.id === ruleId ? { ...rule, ...payload } : { ...rule }));
  }

  async function deleteRule(serviceRuleId: string) {
    await api.deleteAuditLogAlertConditionRule(serviceRuleId);
  }

  async function getEventRule(ruleId: string) {
    try {
      const data = await api.getEventAlertConditionRule(ruleId);
      return data as EventAlertConditionRuleResponse;
    } catch (error) {
      console.log(error);
    }
  }

  async function getEventRules() {
    try {
      const data = await api.getEventAlertConditionRules();
      return data as EventAlertConditionRulesListResponse;
    } catch (error) {
      console.log(error);
    }
  }

  async function getEventRuleFieldValues() {
    try {
      const data = await api.getEventAlertConditionRuleFieldValues();
      return data as EventAlertFieldValuesResponse;
    } catch (error) {
      console.log(error);
    }
  }

  async function addEventRule(payload: CreateEventAlertConditionRuleRequestPayload) {
    return await api.addEventAlertConditionRule(payload);
  }

  async function updateEventRule(ruleId: string, payload: EditEventAlertConditionRuleRequestPayload) {
    const params = { ruleId, payload };
    return await api.updateEventAlertConditionRule(params);
  }

  async function deleteEventRule(ruleId: string) {
    await api.deleteEventAlertConditionRule(ruleId);
  }

  watch(
    () => [paginationParams.page, paginationParams.pageSize],
    async ([newPage, newPageSize], [oldPage]) => {
      const pageToken = getPageToken(newPage, oldPage, nextPageToken.value, prevPageToken.value);
      const payloadParams: GetRulesParams = {
        pageSize: newPageSize,
      };

      if (pageToken) {
        payloadParams.pageToken = pageToken;
      } else {
        delete payloadParams.pageToken;
      }

      await getRules(payloadParams);
    }
  );

  return {
    rules,
    loadingRules,
    currentRuleName,
    getRules,
    getRule,
    addRule,
    updateRule,
    deleteRule,
    rulesTotalLength,
    nextPageToken,
    prevPageToken,
    getPageToken,
    updatePaginationData,
    paginationParams,
    filterRules,
    updateRuleInStore,
    createRuleBase,
    getEventRule,
    getEventRules,
    getEventRuleFieldValues,
    addEventRule,
    updateEventRule,
    deleteEventRule,
    sourceType,
    backToPreviousPageInfo,
  };
});
