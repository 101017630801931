<template>
  <eewc-sidebar
    class="device-vpn-sidebar"
    :title="`${t('Tunnel')} • ${deviceName}`"
    :drawer-open="open"
    :actions="false"
    :cancel-button="t('Cancel')"
    data-testid="vpn-sidebar"
    @closeSidebar="emit('close')"
  >
    <template #tabContent>
      <v-card flat>
        <v-card-text>
          <div
            v-if="loading"
            class="details--text"
          >
            {{ t(`Please wait while we establish a connection with this ${deviceType}...`) }}
          </div>
          <div class="details--text">
            {{
              `${t(
                'We have created a tunnel that will open in a new window. If this does not appear, please check if your browser is blocking popups, or'
              )}`
            }}
            <a
              target="_blank"
              class="link--text"
              :href="httpUrl || httpsUrl"
            >
              {{ t('click here to open.') }}
            </a>
          </div>
          <eewc-form-field
            v-if="username"
            :label-width="160"
            :label="t('Default username')"
            data-testid="vpn-sidebar-username-field"
            class="pb-1 pt-5 align-baseline"
          >
            <div class="details--text">
              {{ username }}
            </div>
          </eewc-form-field>
          <div
            v-if="displayPassword"
            class="device-vpn-sidebar__password-section"
          >
            <eewc-form-field
              :label-width="160"
              :label="t('Default password')"
              data-testid="vpn-sidebar-password-field"
              class="align-baseline"
            >
              <div class="details--text">
                {{ displayPassword }}
              </div>
            </eewc-form-field>
            <eewc-button-common
              v-if="isPasswordViewAllowed"
              class="device-vpn-sidebar__password-section__icon"
              type="clear"
              :icon-size="24"
              small
              icon
              :append-icon="showPassword ? '$icon_no_permissions' : '$icon_eye'"
              data-testid="vpn-sidebar-toggle-password-button"
              @click="handleShowPassword"
            />
          </div>
          <eewc-form-field
            v-if="notes"
            :label-width="160"
            :label="t('Notes')"
            data-testid="vpn-sidebar-notes-field"
            class="pb-1 pt-5 align-baseline"
          >
            <div class="details--text">
              {{ notes }}
            </div>
          </eewc-form-field>
        </v-card-text>
      </v-card>
    </template>
  </eewc-sidebar>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, onUnmounted } from 'vue';

import {
  CameraTunnelResponse,
  ApiCameraSettings,
  ApiCameraWithIncludes,
  SpeakerWithIncludes,
} from '@eencloud/eewc-components/src/service/api-types';

import { t } from '@/plugins/i18n.ts';
import {
  useAvailableDevicesStore,
  useCamerasSettingsStore,
  useCamerasStore,
  useMultiCamerasStore,
  useSpeakersStore,
} from '@/stores';

const props = defineProps<{
  open: boolean;
  deviceId: string;
  deviceType: 'camera' | 'speaker' | 'multiCamera';
  deviceName: string;
  deviceSource?: 'availableDevices';
  isPasswordViewAllowed?: boolean;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const camerasStore = useCamerasStore();
const cameraSettingsStore = useCamerasSettingsStore();
const multiCamerasStore = useMultiCamerasStore();
const speakersStore = useSpeakersStore();
const availableDevicesStore = useAvailableDevicesStore();

const loading = ref();
const httpUrl = ref<string>();
const httpsUrl = ref<string>();
const cameraSettings = ref<ApiCameraSettings>();
const notes = ref<string>();
const showPassword = ref(false);
const cameraData = ref<ApiCameraWithIncludes>();
const speakerData = ref<SpeakerWithIncludes>();
const cameraTunnel = ref<CameraTunnelResponse>();

const adminCredentials = computed(() => cameraData.value?.adminCredentials);
const store = computed(() => {
  if (props.deviceSource === 'availableDevices') return availableDevicesStore;
  switch (props.deviceType) {
    case 'camera':
      return camerasStore;
    case 'multiCamera':
      return multiCamerasStore;
    case 'speaker':
      return speakersStore;
    default:
      return null;
  }
});
const displayPassword = computed(() => {
  let password: string = '';
  switch (props.deviceType) {
    case 'camera':
      password =
        (adminCredentials.value ? adminCredentials.value?.password : cameraSettings.value?.credentials?.password) ?? '';
      break;
    case 'speaker':
      password = speakerData.value?.settings?.adminCredentials?.password ?? '';
      break;
  }

  const maskedPassword = '•'.repeat(6);
  if (password) {
    return showPassword.value ? password : maskedPassword;
  }
});
const username = computed(() => {
  let username: string = '';
  switch (props.deviceType) {
    case 'camera':
      username =
        (adminCredentials.value ? adminCredentials.value.username : cameraSettings.value?.credentials?.username) ?? '';

      break;
    case 'speaker':
      username = speakerData.value?.settings?.adminCredentials?.username ?? '';
      break;
  }

  return username;
});

function handleShowPassword() {
  showPassword.value = !showPassword.value;
}

onMounted(() => {
  createTunnel();
  getDeviceData();
});

async function getDeviceData() {
  loading.value = true;

  if (props.deviceSource !== 'availableDevices') {
    switch (props.deviceType) {
      case 'camera':
        const cameraSettingsData = await cameraSettingsStore.fetchCameraSettings(props.deviceId);
        cameraSettings.value = cameraSettingsData?.data;
        cameraData.value = (await camerasStore.getCamera(props.deviceId)) as ApiCameraWithIncludes;
        notes.value = cameraData.value?.notes ?? '';
        break;
      case 'multiCamera':
        notes.value = multiCamerasStore.multiCamera(props.deviceId)?.notes;
        break;
      case 'speaker':
        speakerData.value = speakersStore.speaker(props.deviceId);
        notes.value = speakerData.value?.notes ?? '';
        break;
    }
  }

  loading.value = false;
}

async function createTunnel() {
  loading.value = true;
  cameraTunnel.value = (await store.value?.createTunnel(props.deviceId)) as CameraTunnelResponse;
  if (!cameraTunnel.value) return;

  httpUrl.value = cameraTunnel.value.httpUrl;
  httpsUrl.value = cameraTunnel.value.httpsUrl;
  window.open(httpUrl.value || httpsUrl.value, '_blank');
  loading.value = false;
}

onUnmounted(() => {
  if (cameraTunnel.value) store.value?.deleteTunnel(props.deviceId);
});
</script>

<style lang="scss">
@import '@/styles/public/main.scss';
.device-vpn-sidebar {
  .details--text {
    @include body-2;
    color: $primary;
  }
  .link--text {
    color: $accent;
  }

  &__password-section {
    display: flex;
    justify-content: space-between;

    &__icon {
      margin-top: 6px;
    }
  }
}
</style>
