import { defineStore } from 'pinia';
import { ref } from 'vue';
import { logOut } from '@eencloud/eewc-components/src/service/auth.js';

export const useAuthStateStore = defineStore('authState', function () {
  const loggedIn = ref(false);

  function loginSuccessful() {
    loggedIn.value = true;
  }

  function logout() {
    loggedIn.value = false;
    logOut();
  }

  return {
    loggedIn,
    loginSuccessful,
    logout,
  };
});
