import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiCameraSettings,
  CameraIOSettingsQueryParams,
  CameraSettingsResponse,
  PortPatch,
} from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCamerasSettingsStore = defineStore('cameraSettings', () => {
  const camerasSettings = ref<Record<string, CameraSettingsResponse | undefined>>({});
  const loading = ref(false);

  async function fetchCameraSettings(cameraId: string): Promise<CameraSettingsResponse | undefined> {
    // Verify if camera settings are already in the cache and if the settings have been updated
    if (camerasSettings.value[cameraId]) {
      return camerasSettings.value[cameraId];
    }

    const params = {
      // include: 'schema', Api crash when include schema
      include: 'proposedValues',
    };

    try {
      loading.value = true;
      // Make the API call if the settings are not cached
      const cameraSettingsResponse = await api.fetchCameraSettings(cameraId, params);
      if (cameraSettingsResponse) {
        camerasSettings.value[cameraId] = cameraSettingsResponse;
        return cameraSettingsResponse;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  async function updateCameraSettings(cameraId: string, payload: ApiCameraSettings) {
    return await api.patchCameraSettings(cameraId, { data: payload });
  }

  async function fetchIOSettings(cameraId: string, params: CameraIOSettingsQueryParams = {}) {
    return await api.fetchCameraIOSettings(cameraId, params);
  }

  async function updateIOSettings(cameraId: string, params: PortPatch & { portId: string }[]) {
    const statuses = await Promise.all(
      params.map((param) => {
        const { portId, ...payload } = param;
        return api.updateCameraIOSettings(cameraId, portId, { ...payload, id: undefined, type: undefined });
      })
    );
    const allSuccess = statuses.every((status) => status === 204);
    return allSuccess;
  }

  return {
    camerasSettings,
    loading,
    fetchCameraSettings,
    updateCameraSettings,
    fetchIOSettings,
    updateIOSettings,
  };
});
